import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "./company/companySlice";
import userReducer from "./user/userSlice";
import balanceReducer from "./user/balanceSlice";
import configReducer from "./config/configSlice";
import tokenReducer from "./token/tokenSlice";
import templateReducer from "./template/templateSlice";
import menuReducer from "./menu/menuSlice";

export const store = configureStore({
  reducer: {
    company: companyReducer,
    user: userReducer,
    balance: balanceReducer,
    config: configReducer,
    token: tokenReducer,
    template: templateReducer,
    menu: menuReducer,
  },
});

export default store;
