import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cil3d,
  cilAperture,
  cilApplications,
  cilBank,
  cilBuilding,
  cilMonitor,
  cilNewspaper,
  cilWallet,
  cilBasket,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

const _nav = [
  {
    component: CNavGroup,
    name: "Marketplace",
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
    permission: ["all"],
    items: [
      {
        component: CNavItem,
        name: "Daftar Pesanan",
        to: `/marketplace/orders`,
        permission: "all",
      },
    ],
  },
];

export default _nav;
