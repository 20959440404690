import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { saveState, loadState } from '../browser-storage/browser-storage'

const initialState = {
  loading: false,
}

export const configSlice = createSlice({
  name: 'config',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoading } = configSlice.actions

export default configSlice.reducer
