import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cil3d,
  cilApplications,
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCreditCard,
  cilCursor,
  cilDescription,
  cilDollar,
  cilDrop,
  cilMoney,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
  cilUser,
  cilNewspaper,
  cilAperture,
  cilCart,
  cilBasket,
  cilScreenSmartphone,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import { useAppSelector } from "src/core/feature/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

const _nav = [
  // {
  //   component: CNavItem,
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
  //   permission: "all",
  // },
  {
    component: CNavGroup,
    name: "Marketplace",
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
    permission: ["all"],
    items: [
      {
        component: CNavItem,
        name: "Pengaturan Data Profil",
        to: `/profile`,
        permission: "all",
      },
      {
        component: CNavItem,
        name: "Atur Pengiriman",
        to: `/ship`,
        permission: "all",
      },
      {
        component: CNavItem,
        name: "Data Produk & Layanan",
        to: `/marketplace/product`,
        permission: "all",
      },
      {
        component: CNavItem,
        name: "Daftar Pesanan",
        to: `/marketplace/orders`,
        permission: "all",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Sistem Kasir",
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
    permission: ["all"],
    items: [
      {
        component: CNavItem,
        name: "Data Kategori",
        to: `/pos/category`,
        permission: "all",
      },
      {
        component: CNavItem,
        name: "Manajemen Produk & Layanan",
        to: `/pos/product`,
        permission: "all",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Manajemen Vendor",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    permission: ["all"],
    items: [
      {
        component: CNavItem,
        name: "Role Manajemen Vendor",
        to: `/role-vendor`,
        permission: "all",
      },
      // {
      //   component: CNavItem,
      //   name: "Konfigurasi Menu Vendor",
      //   to: `/pos/product`,
      //   permission: "all",
      // },
      {
        component: CNavItem,
        name: "Data Vendor",
        to: `/vendor`,
        permission: "all",
      },
    ],
  },
];

export default _nav;
