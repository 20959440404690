// import * as API_TRANSACTION from "../core/service/api_transaction";
// import * as API_COMMERCE from "../core/service/api_commerce";
// import * as API_DIGIPROD from "../core/service/api_digiprod";

import moment from "moment";
import ImageIcon from "../assets/images/Iconly/Light-Outline/Image.svg";
import ProfileIcon from "../assets/images/Iconly/Bold/Profile.svg";
// var idLocale = require("moment/locale/id");
// const momentLocal = moment.locale("id", idLocale);

export const createArrayEntityFromObject = (obj) => {
  return Object.keys(obj);
};

export const getEntity = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        // return obj[k];
        return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const getEntityPy = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean" || typeof obj[k] == "number") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        // return obj[k];
        return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const createQueryUrl = (arr, separator) => {
  let query = "";
  arr.map((el, n) => {
    if (arr.length - 1 > n)
      return (query = `${query}${el.key}=${el.value}${separator}`);
    if (typeof el.value == "boolean" || typeof el.value == "number") {
      // console.log("el", el);
      query = `${query}${el.key}=${el.value}`;
    } else if (el.value) {
      query = `${query}${el.key}=${el.value}`;
    }
  });
  // console.log(query);
  return query;
};

export const findArray = (arr, fromKey, value, returnKey) => {
  if (arr.find((el) => el[fromKey].includes(value))) {
    return arr.find((el) => el[fromKey].includes(value))[returnKey];
  } else {
    return null;
  }
};

export const formatCurrency = (value) => {
  if (value == "" || value == null || value == undefined) return "Rp 0";
  return new Intl.NumberFormat("in-IN", {
    style: "currency",
    currency: "IDR",
  }).format(value);
};

export const onlyNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    return evt.preventDefault();
  return true;
};

export const isNumeric = (number) => {
  if (+number === +number) {
    // if is a number
    return true;
  }

  return false;
};

export const backTo = (url, query = {}) => {
  window.location.replace(url);
};

export const checkType = (data) => {
  if (data) {
    if (parseInt(data) != NaN) {
      return parseInt(data);
    }
    return data;
  }
};

export const createNewArray = (array, keys, nested = {}) => {
  let returnArr = [];
  array.map((arr) => {
    let obj = {};
    keys.map((key) => {
      obj[key.to] = arr[key.from];
    });
    if (nested && arr[nested.from]) {
      obj[nested.to] = createNewArray(arr[nested.from], keys, nested);
    }
    returnArr.push(obj);
  });
  return returnArr;
};

export const downloadPdf = (transaction_type, id, query = {}) => {
  // console.log(transaction_type);
  if (transaction_type === "marketplace_transactions") {
    API_COMMERCE.downloadPDFMarketplace(id)
      .then((response) => {
        let name = "MARKETPLACE.pdf"; //response.data.data.url.split();
        let url = response.data.url;
        var link = document.createElement("a");
        // console.log(response);
        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  } else if (transaction_type === "digital_product_transactions") {
    API_DIGIPROD.downloadPDF(id)
      .then((response) => {
        // console.log(response);
        let name = response.data.name;
        let url = response.data.url;
        var link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = name;
        // console.log(name);
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  } else if (transaction_type === "pencairan_dana_admin") {
    // console.log(id);
    API_TRANSACTION.downloadPDFPencairanDana(id)
      .then((response) => {
        // console.log(response);
        let name = "PencairanDanaAdmin.pdf"; //response.data.data.url.split();
        let url = response.data.data.url;
        var link = document.createElement("a");
        // console.log(response);
        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  } else {
    API_TRANSACTION.downloadPDF(transaction_type, id, query)
      .then((response) => {
        // console.log(response);
        let name = response.data.data.url.split();
        var link = document.createElement("a");
        link.download = name[name.length - 1];
        link.href = response.data.data.url;
        link.click();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }
};
export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return moment(date).format("MMMM");
};

export const subStringText = (message, start, end, a) => {
  if (message?.length >= a) {
    return message.substring(start, end) + "...";
  } else {
    return message;
  }
};

export const camelCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((el, ind) => {
      if (el) {
        return el[0].toUpperCase() + el.substring(1, el.length);
      }
    })
    .join(" ");
};

export const replaceText = (text, from, to) => {
  return text.replaceAll(from, to);
};

export const humanizedText = (text) => {
  return text.replaceAll("-", " ").replaceAll("_", " ");
};

export const formatDatePicker = (date) => {
  let arrDate = new Date(date),
    mnth = ("0" + (arrDate.getMonth() + 1)).slice(-2),
    day = ("0" + arrDate.getDate()).slice(-2);
  return [arrDate.getFullYear(), mnth, day].join("-");
};

export const formatTimePicker = (date) => {
  let d = new Date(date);
  let datetext = d.toTimeString();
  return (datetext = datetext.split(" ")[0]);
};

export const formatDatePickertoIsoString = (date) => {
  let arrDate = new Date(date);
  return arrDate.toISOString();
};

export const formatTimetoDate = (date) => {
  // let arrDate = new Date(date);
  let tempTime = date.split(":");
  let dt = new Date();
  dt.setHours(tempTime[0]);
  dt.setMinutes(tempTime[1]);
  dt.setSeconds(tempTime[2]);
  return dt;
};

export const formatDateTime = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const formatDate = (date, format = "ymd") => {
  switch (format) {
    case "ymd":
      return moment(date).format("YYYY-MM-DD");

      break;
    case "dmy":
      return moment(date).format("DD-MM-YYYY");

      break;
    case "hms":
      return moment(date).format("HH:mm:ss");

      break;
    case "fromNow":
      return moment(date).fromNow();

      break;

    default:
      return moment(date).format("YYYY-MM-DD");
      break;
  }
};
export const formatDateCustom = (date, format = "") => {
  return moment(date).format(format);
};
export const formatDateTimeUTC = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  return moment.utc(date).local().format(format);
};

export const formatTime = (time) => {
  let a = time.split(".")[0];
  let b = new Date();
  b.setMinutes(a.split(":")[1]);
  b.setSeconds(a.split(":")[2]);
  b.setHours(parseInt(a.split(":")[0]) + 6);
  // console.log(6 + a.split(":")[0]);
  return formatDate(b, "hms");
};

export const check = () => {
  if (localStorage.getItem("token_sn_merchant_redux"))
    return JSON.parse(
      localStorage.getItem("token_sn_merchant_redux")
    ).replaceAll('"', "");
  return "";
};

export const checkRegex = (type, value, strength) => {
  let regex = "";
  switch (type) {
    case "email":
      regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      break;
    case "password":
      switch (strength) {
        case "strong":
          regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,30}$/;
          break;
        case "medium":
          regex = /^(?=.*[a-zA-Z])(?=.*\d).{4,30}$/;
          break;
        case "low":
          regex = /^(?=.*[a-zA-Z0-9]).{1,30}$/;
          break;
        default:
          return false;
      }
      break;
    case "noId":
      regex = /^[a-zA-Z0-9\-_]+$/;
      break;
    case "decimal":
      regex = /^\d*\.?\d+$/;
      break;
    default:
      return false;
  }

  return regex.test(value);
};

export const AlertPasswordStrength = (strength) => {
  let alert = "";
  switch (strength) {
    case "strong":
      alert =
        "Password harus terdiri dari 6-30 karakter, harus mengandung minimal 1 huruf besar, 1 huruf kecil, 1 angka, dan 1 karakter spesial";
      break;
    case "medium":
      alert = "Password harus terdiri dari 4-30 karakter, harus mengandung minimal 1 huruf dan 1 angka";
      break;
    case "low":
      alert = "Password harus terdiri dari 1-30 karakter harus mengandung 1 angka atau 1 huruf";
      break;

    default:
      break;
  }

  return alert;
};

export const responseArray = (value) => {
  return value.join(", ");
};

export const detailCardTransaction = (value) => {
  switch (value) {
    case "update_inquiry":
      return value;
    case "access_parking_card_payment":
      return "Pembayaran Kartu Parkir";
    default:
      return value;
  }
};

export const imageOnError = (event, type = "") => {
  event.currentTarget.src = ImageIcon;
  if (type === "user") event.currentTarget.src = ProfileIcon;
  event.currentTarget.className = `${event.currentTarget.className} error`;
};

export const handleImageError = (event, type = "") => {
  event.currentTarget.src = ImageIcon;
  // if (type === "user") event.currentTarget.src = ProfileIcon;
  event.currentTarget.className = `${event.currentTarget.className} error`;
};

export const handleDeletedObjectArray = (obj, returnKey) => {
  _.map(returnKey, (el) => {
    if (!obj[el]) obj[el] = "-";
  });
  return obj;
};

export const getComboData = (value, combo, key) => {
  let isFind = _.find(combo, { value });
  // console.log(isFind);
  return isFind ? isFind[key] : "-";
};

export const elTablePaging = (item, paging) => {
  return (
    (item > paging.page - 2 && item <= paging.page + 3) ||
    (paging.page < 2 && item <= paging.page + 5 - paging.page) ||
    (paging.page < 2 && item <= paging.page + 5 - paging.page) ||
    (paging.page >= paging.totalPages - 2 && item > paging.totalPages - 5)
  );
};
