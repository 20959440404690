import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CImage,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { AppSidebarNav } from "./AppSidebarNav";

import { logoNegative } from "src/assets/brand/logo-negative";
import { sygnet } from "src/assets/brand/sygnet";
import icon from "../assets/images/logo-solusi.svg";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "../_nav";
import navigationVendor from "../_navVendor";
import { useAppDispatch, useAppSelector } from "src/core/feature/hooks";
import {
  setSidebarShow,
  setSidebarUnfoldable,
} from "src/core/feature/template/templateSlice";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useAppSelector(
    (state) => state.template.sidebarUnfoldable
  );
  const sidebarShow = useAppSelector((state) => state.template.sidebarShow);
  const userData = useAppSelector((state) => state.user.user);
  let count = 0;
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => dispatch(setSidebarShow(visible))}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage
          src={icon}
          style={{
            objectFit: "contain",
            width: 200,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {userData?.userType == "MERCHANT" ? (
            <AppSidebarNav items={navigation} />
          ) : (
            <AppSidebarNav items={navigationVendor} />
          )}
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(setSidebarUnfoldable(!unfoldable))}
      /> */}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
