import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CFormText,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from "@coreui/icons";
import * as API from "../core/services/api";
import { AppBreadcrumb } from "./index";
import { AppHeaderDropdown } from "./header/index";
import { logo } from "src/assets/brand/logo";
import { setSidebarShow } from "src/core/feature/template/templateSlice";
import { useAppDispatch, useAppSelector } from "src/core/feature/hooks";
import { toast } from "react-toastify";
import { setUser } from "src/core/feature/user/userSlice";
import { setToken } from "src/core/feature/token/tokenSlice";
import { setLoading } from "src/core/feature/config/configSlice";
import { removeMenu } from "src/core/feature/menu/menuSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";


const AppHeader = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user.user);
  const sidebarShow = useAppSelector((state) => state.template.sidebarShow);
  let navigate = useNavigate();

  const changeCompany = () => {
    dispatch(setLoading(true));
    API.changeCompany()
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(removeMenu());
        dispatch(setUser(response.data.data));
        dispatch(setToken(response.data.data.authToken));
        toast.success("Berhasil");
        window.location.replace("/dashboard-monitor");
      })
      .catch((error) => {
        dispatch(setLoading(false));
      });
  };

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(setSidebarShow(!sidebarShow))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        {/* <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand> */}
        <CHeaderNav className="d-none d-md-flex me-auto">
          {/* {user?.companyId ? (
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
              {user?.companyName}
            </div>
          ) : (
            <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
              SUPERADMIN
            </div>
          )} */}

          {/* <CNavItem></CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        {user?.companyId && user?.userType == "SUPERADMIN" ? (
          <CHeaderNav>
            <CButton
              color=""
              type="button"
              size="sm"
              style={{
                background: "#032E9A",
                color: "#fff",
              }}
              onClick={() => {
                changeCompany();
              }}
            >
              {" "}
              Kembali ke Dashboard Superadmin
            </CButton>
          </CHeaderNav>
        ) : (
          ""
        )}
        {user?.companyId && user?.userType == "ADMIN" ? (
          <NavLink to="/notification" className="ms-3 text-decoration">
            <FontAwesomeIcon icon={faBell} className="me-2" style={{height:"1.7rem"}}/>
          </NavLink>
        ) : (
          ""
        )}

        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  );
};

export default AppHeader;
