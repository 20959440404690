export const ValidateSuccess = (respon) => {
  if (respon.type == "SUCCESS_LOGOUT") {
    return "Berhasil Logout";
  } else if (respon.type == "SUCCESS_LOGIN") {
    return "Berhasil Login";
  } else if (respon.type == "SUCCESS_REQUEST_OTP") {
    return "Permintaan OTP Berhasil";
  } else if (respon.type == "SUCCESS_VALIDATE_OTP") {
    return "Validasi OTP Berhasil";
  } else if (respon.type == "SUCCESS_VALIDATE_SECURITY_CODE") {
    return "Validasi Kode Keamanan Berhasil";
  } else if (respon.type == "SUCCESS_VALIDATE_SECURITY_CODE_TOKEN") {
    return "Validasi Kode Keamanan Token Berhasil";
  } else if (respon.type == "SUCCESS_GET_OTP_CONFIG") {
    return "Konfigurasi OTP Berhasil Didapatkan";
  } else if (respon.type == "SUCCESS_UPDATE_OTP_CONFIG") {
    return "Konfigurasi OTP Berhasil Dirubah";
  } else if (respon.type == "SUCCESS_CREATED") {
    return "Berhasil Dibuat";
  } else if (respon.type == "SUCCESS_CREATE_ROLE") {
    return "Berhasil Dibuat";
  } else if (respon.type == "SUCCESS_UPDATED") {
    return "Berhasil Dirubah";
  } else if (respon.type == "SUCCESS_DELETED") {
    return "Berhasil Dihapus";
  } else if (respon.type == "PASSWORD_RESET") {
    return "Reset Password Berhasil";
  } else if (respon.type == "PASSWORD_UPDATED") {
    return "Password Berhasil Dirubah";
  } else if (respon.type == "SUCCESS_CREATE_SUB_MERCHANT") {
    return "Data Berhasil Dibuat";
  } else if (respon.type == "SUCCESS_CREATE_CREDENTIAL") {
    return "Berhasil Membuat Credential";
  } else if (respon.type == "OK") {
    return "Berhasil";
  } else if (respon.type == "") {
    return "Berhasil";
  } else {
    return respon.type;
  }
};

export const ValidateError = (respon, config) => {
  // console.log(respon.detail.status_code, respon.detail.type, "tes");
  switch (parseInt(respon.detail.status_code)) {
    case 400:
      if (respon.detail.type == "INVALID_COMPANY") {
        return "Company Tidak Sah";
      } else if (respon.detail.type == "USER_NOT_FOUND") {
        return "Pengguna Tidak Ditemukan";
      } else if (respon.detail.type == "USER_INACTIVE") {
        return "Pengguna Tidak Ditemukan";
      } else if (respon.detail.type == "WRONG_PASSWORD_COUNT_EXCEEDED") {
        return "Percobaan Melebihi Batas, Silahkan Reset Password Anda";
      } else if (respon.detail.type == "AUTHENTICATION_FAILED") {
        return "Otentikasi Gagal";
      } else if (respon.detail.type == "OTP_NOT_FOUND") {
        return "OTP Tidak Ditemukan";
      } else if (respon.detail.type == "SECURITY_CODE_NOT_MATCH") {
        return "Kode Keamanan Tidak Cocok";
      } else if (respon.detail.type == "USER_DO_NOT_HAVE_SECURITY_CODE") {
        return "Pengguna Tidak Memiliki Kode Keamanan";
      } else if (respon.detail.type == "SECURITY_CODE_TOKEN_NOT_FOUND") {
        return "Token Kode Keamanan Tidak Ditemukan";
      } else if (respon.detail.type == "BAD_REQUEST") {
        return "Permintaan Buruk";
      } else if (respon.detail.type == "INVALID_DATE_FORMAT") {
        return "Format Tanggal Tidak Valid";
      } else if (respon.detail.type == "INVALID_PHONE_NUMBER") {
        return respon.detail.message;
      } else if (respon.detail.type == "INVALID_EMAIL") {
        return "Email Tidak Sesuai, Isi Email Dengan Benar";
      } else if (respon.detail.type == "INVALID_OBJECT_ID") {
        return "ID Obyek Tidak Valid";
      } else if (respon.detail.type == "INVALID_PASSWORD_LENGTH") {
        return "Panjang Password Tidak Valid";
      } else if (respon.detail.type == "FAILED_TO_REQUEST") {
        return "Gagal Meminta";
      } else if (respon.detail.type == "COMPANY_INITIAL_OR_NAME_EXIST") {
        return "Inisial atau Nama Perusahaan Ada";
      } else if (respon.detail.type == "DUPLICATE_EMAIL") {
        return "Email Duplikat";
      } else if (respon.detail.type == "TOKEN_EXPIRED") {
        return "Token Kadaluwarsa";
      } else if (respon.detail.type == "TOKEN_ALREADY_USED") {
        return "Token Sudah Digunakan";
      } else if (respon.detail.type == "WRONG_CONFIRMATION_PASSWORD") {
        return "Password Konfirmasi Salah";
      } else if (respon.detail.type == "WRONG_PASSWORD") {
        return "Password Salah";
      } else if (respon.detail.type == "USERNAME_ALREADY_EXIST") {
        return "Username Tidak Boleh Sama";
      } else if (respon.detail.type == "INVALID_SIZE_IMAGE") {
        return "Dimensi gambar tidak boleh lebih dari 1063x650";
      } else if (respon.detail.type == "CARD_BLOCKED") {
        return "Kartu Sudah Terblokir";
      } else if (respon.detail.type == "INVALID_NOMINAL") {
        return "Nominal tidak boleh kurang dari atau sama dengan 0";
      } else if (respon.detail.type == "USER_ALREADY_HAVE_BALANCE") {
        return "Gagal Menghapus Konfigurasi Saldo, Pengguna Masih Memiliki Saldo";
      } else if (respon.detail.type == "RESOURCE_ALREADY_EXIST") {
        return "Konfigurasi Saldo Sudah Tersedia";
      } else if (
        respon.detail.type ==
        "START_DATE_END_DATE_DIFFERENCE_MUST_BE_LESS_THAN_OR_EQUAL_TO_THIRTY_DAYS"
      ) {
        return "Rentang tanggal maksimal 30 hari";
      } else if (
        respon.detail.type == "PAYMENT_METHOD_OPTION_NOT_YET_SUPPORTED"
      ) {
        return "Metode Pembayaran Tidak Tersedia";
      } else if (
        respon.detail.type ==
        "DEFAULT_MINUTES_UNTIL_EXPIRED_MUST_BE_BIGGER_THAN_ZERO"
      ) {
        return "Batas Waktu belum di masukkan";
      } else if (respon.detail.type == "ALREADY_EXISTS") {
        return "Data Sudah Ada";
      } else if (respon.detail.type === "DISCOUNT_CANNOT_BE_LESS_THAN_ZERO") {
        return "Diskon Harus lebih besar Dari Nol";
      } else if (
        respon.detail.type ===
        "AMOUNT_IN_DETAIL_ITEM_INVOICE_MUST_BE_BIGGER_THAN_ZERO"
      ) {
        return "Nominal di Detail Invoice Harus lebih besar Dari Nol";
      } else if (
        respon.detail.type ===
        "EXPIRED_DATE_MUST_BE_LATER_OR_EQUAL_TO_INVOICE_DATE"
      ) {
        return "Tanggal Jatuh Tempo Harus lebih dari atau sama dengan Tanggal Invoice";
      } else if (
        respon.detail.type ===
        "EXPIRED_DATE_MUST_BE_LATER_THAN_OR_EQUAL_TO_THE_CURRENT_DATE"
      ) {
        return "Tanggal Jatuh Tempo Harus lebih dari atau sama dengan Tanggal Saat ini";
      } else if (
        respon.detail.type ===
        "INVOICE_DATE_MUST_BE_LATER_OR_THE_SAME_AS_THE_CURRENT_DATE"
      ) {
        return "Tanggal Invoice Harus lebih dari atau sama dengan Tanggal Saat ini";
      } else if (
        respon.detail.type === "AMOUNT_MINUS_DISCOUNT_CANNOT_BE_LESS_THAN_ZERO"
      ) {
        return "Nominal Dikurangi Diskon Harus lebih besar Dari Nol";
      } else if (
        respon.detail.type ===
        "TOTAL_AMOUNT_INVOICE_CANNOT_BE_ZERO_OR_LESS_THAN_ZERO"
      ) {
        return "Total Invoice harus lebih besar Dari Nol";
      } else if (
        respon.detail.type ===
        "INVOICE_DATE_CANNOT_BE_NULL_IF_IS_REPEATING_IS_FALSE"
      ) {
        return "Tanggal Invoice Harus Diisi Apabila Fitur Berulang Tidak Aktif";
      } else if (
        respon.detail.type ===
        "EXPIRED_DATE_CANNOT_BE_NULL_IF_IS_REPEATING_IS_FALSE"
      ) {
        return "Tanggal Jatuh Tempo Harus Diisi Apabila Fitur Berulang Tidak Aktif";
      } else if (
        respon.detail.type ===
        "REPEATING_UNLIMITED_CANNOT_BE_TRUE_AND_REPEATING_METHOD_MAKE_ALL_AT_THE_SAME_TIME"
      ) {
      } else if (respon.detail.type === "PRODUCT_CODE_ALREADY_EXIST") {
        return "Kode produk telah ada";
      } else if (
        respon.detail.type === "REPEATING_RANGE_MUST_BE_BIGGER_THAN_ZERO"
      ) {
        return "Durasi Perulangan Harus lebih Dari Nol";
      } else if (
        respon.detail.type ===
        "RANGE_BETWEEN_INVOICE_DATE_AND_EXPIRED_DATE_MUST_BE_BIGGER_OR_EQUAL_TO_ZERO"
      ) {
        return "Rentang Tanggal Invoice dan Tanggal Jatuh Tempo harus lebih besar Dari Nol";
      } else if (respon.detail.type === "REPEATING_START_DATE_CANNOT_BE_NULL") {
        return "Tanggal Mulai Perulangan Tidak Boleh Kosong";
      } else if (respon.detail.type === "REPEATING_END_DATE_CANNOT_BE_NULL") {
        return "Tanggal Selesai Perulangan Tidak Boleh Kosong";
      } else if (
        respon.detail.type ===
        "REPEATING_START_DATE_MUST_BE_LATER_THAN_CURRENT_DATE"
      ) {
        return "Tanggal Mulai Perulangan Harus lebih Dari Tanggal Saat ini";
      } else if (
        respon.detail.type ===
        "REPEATING_END_DATE_MUST_BE_LATER_OR_EQUAL_TO_REPEATING_START_DATE"
      ) {
        return "Tanggal Selesai Perulangan Harus lebih Dari Tanggal Saat ini";
      } else if (
        respon.detail.type ===
        "REPEATING_RANGE_MUST_BE_BIGGER_THAN_RANGE_BETWEEN_INVOICE_DATE_AND_EXPIRED_DATE"
      ) {
        return "Durasi Perulangan Harus lebih besar Dari Rentang Tanggal Invoice dan Tanggal Jatuh Tempo";
      } else if (
        respon.detail.type === "REPEATING_START_MONTH_CANNOT_BE_NULL"
      ) {
        return "Bulan Mulai Perulangan Tidak Boleh Kosong";
      } else if (respon.detail.type === "REPEATING_END_MONTH_CANNOT_BE_NULL") {
        return "Bulan Selesai Perulangan Tidak Boleh Kosong";
      } else if (respon.detail.type === "REPEATING_START_YEAR_CANNOT_BE_NULL") {
        return "Tahun Mulai Perulangan Tidak Boleh Kosong";
      } else if (respon.detail.type === "REPEATING_END_YEAR_CANNOT_BE_NULL") {
        return "Tahun Selesai Perulangan Tidak Boleh Kosong";
      } else if (
        respon.detail.type === "REPEATING_DATE_OF_MONTH_CANNOT_BE_NULL"
      ) {
        return "Tanggal Perulangan Tidak Boleh Kosong";
      } else if (
        respon.detail.type ===
        "REPEATING_START_MONTH_YEAR_MUST_BE_LESS_OR_EQUAL_TO_REPEATING_END_MONTH_YEAR"
      ) {
        return "Bulan Tahun Mulai Perulangan Harus kurang dari atau sama dengan Bulan Tahun Selesai Perulangan";
      } else if (
        respon.detail.type ===
        "REPEATING_START_MONTH_YEAR_MUST_BE_LATER_THAN_CURRENT_MONTH_YEAR"
      ) {
        return "Bulan Tahun Mulai Perulangan Harus lebih dari Bulan Tahun Saat ini";
      } else if (
        respon.detail.type ===
        "REPEATING_DATE_OF_MONTH_MUST_BE_BETWEEN_1_AND_28"
      ) {
        return "Tanggal Perulangan Harus Diantara 1 dan 28";
      } else if (respon.detail.type === "INVOICE_ALREADY_CREATED_FROM_MASTER") {
        return "Invoice Sudah Terbuat dari Master";
      } else if (respon.detail.type === "INVOICE_MUST_BE_UNPAID") {
        return "Invoice Harus Belum Terbayar";
      } else if (respon.detail.type === "USER_CANNOT_CREATE_INVOICE_FOR_SELF") {
        return "User Tidak Bisa Membuat Invoice Untuk Dirinya Sendiri";
      } else if (
        respon.detail.type === "INVOICE_CUSTOMER_IS_NOT_CURRENT_USER"
      ) {
        return "User Tertagih Bukan User Saat Ini";
      } else if (respon.detail.type === "INVOICE_CREATOR_IS_NOT_CURRENT_USER") {
        return "User Pembuat Invoice Bukan User Saat Ini";
      } else if (
        respon.detail.type ===
        "INVOICE_COMPANY_IS_NOT_THE_SAME_AS_CURRENT_USER_COMPANY"
      ) {
        return "Invoice Perusahaan Tidak Sama Dengan Perusahaan User Saat ini";
      } else if (respon.detail.type === "INVOICE_MUST_BE_PAID") {
        return "Invoice Harus Terbayar";
      } else if (
        respon.detail.type ===
        "INPUT_AMOUNT_MUST_BE_SMALLER_OR_EQUAL_TO_INVOICE_FINAL"
      ) {
        return "Nominal Harus lebih kecil atau sama dengan Total Invoice";
      } else if (respon.detail.type === "AMOUNT_MUST_BE_BIGGER_THAN_ZERO") {
        return "Nominal Harus lebih besar dari Nol";
      } else if (respon.detail.type === "INVOICE_ALREADY_PAID") {
        return "Invoice Telah Terbayar";
      } else if (respon.detail.type === "INVOICE_IS_UNPAID") {
        return "Invoice Belum Terbayar";
      } else if (respon.detail.type === "MASTER_INVOICE_MUST_BE_ACTIVE") {
        return "Invoice Master Harus Aktif";
      } else if (
        respon.detail.type ===
        "INVOICE_LINK_USER_ID_MUST_BE_THE_SAME_AS_INVOICE_USER_ID"
      ) {
        return "Link Id Harus sama dengan User Id Tertagih";
      } else if (respon.detail.type === "FIELD_NOT_EXIST") {
        return "Field Tidak Tersedia";
      } else if (
        respon.detail.type ===
        "START_DATE_END_DATE_DIFFERENCE_MUST_BE_LESS_OR_EQUAL_TO_THIRTY_DAYS"
      ) {
        return "Perbedaan Rentang Tanggal Mulai Dan Tanggal Selesai Harus lebih sedikit atau sama dengan 30 hari";
      } else if (respon.detail.type === "INVALID_COLUMN") {
        return "Kolom Tidak Valid";
      } else if (respon.detail.type === "UPLOADED_EXCEL_HAVE_INVALID_COLUMNS") {
        return "Berkas Excel yang Diunggah memiliki Kolom Tidak Valid";
      } else if (respon.detail.type === "TOPIC_MUST_CONFORM_TO_AGREED_FORMAT") {
        return "Topik Harus Menyesuaikan Format Kesepakatan";
      } else if (respon.detail.type === "DESTINATION_BALANCE_NOT_ALLOWED") {
        return "Saldo Tujuan Tidak Diperbolehkan";
      } else if (respon.detail.type === "INVOICE_IS_NOT_CREATED_BY_GATEWAY") {
        return "Invoice Tidak Terbuat oleh Gateway";
      } else if (respon.detail.type === "ROLE_NAME_ALREADY_EXIST") {
        return "Nama role telah ada";
      } else if (respon.detail.type === "STATUS_NOT_VALID") {
        return respon.detail.message;
      } else {
        return respon.detail.message;
      }
    case 401:
      if (respon.detail.type == "WRONG_PASSWORD") {
        return "Password Salah";
      } else if (respon.detail.type == "AUTHENTICATION_FAILED") {
        return "Otentikasi Gagal";
      } else if (respon.detail.type == "UNAUTHORIZED") {
        return "Tidak Diizinkan";
      } else if (respon.detail.type == "UNATHORIZED") {
        return "Sesi Telah Berakhir";
      } else {
        return respon.detail.message;
      }
    case 403:
      if (respon.detail.type == "UNAUTHORIZED_ROLE") {
        return "Akses Tidak Diizinkan";
      } else if (respon.detail.type == "ACCESS_FORBIDDEN") {
        return "Dilarang Akses";
      } else if (respon.detail.type === "SUPERADMIN_MUST_CHANGE_COMPANY") {
        return;
      } else {
        return respon.detail.message;
      }
    case 404:
      if (respon.detail.type == "NOT_FOUND") {
        return "Tidak Ditemukan";
      } else if (respon.detail.type == "COMPANY_NOT_FOUND") {
        return "Perusahaan Tidak Ditemukan";
      } else if (respon.detail.type === "QR_CODE_NOT_FOUND") {
        return "Barcode tidak ditemukan";
      } else if (respon.detail.type === "NOT_FOUND") {
        return "Tidak Ditemukan";
      } else if (respon.detail.type === "ADMIN_NOT_FOUND") {
        return "Admin Tidak Ditemukan";
      } else if (respon.detail.type === "MEMBER_NOT_FOUND") {
        return "Member Tidak Ditemukan";
      } else if (respon.detail.type === "MERCHANT_NOT_FOUND") {
        return "Merchant Tidak Ditemukan";
      } else if (respon.detail.type === "USER_NOT_FOUND") {
        return "User Tidak Ditemukan";
      } else if (respon.detail.type === "INVOICE_TRANSACTION_NOT_FOUND") {
        return "Transksi Invoice Tidak Ditemukan";
      } else if (respon.detail.type === "INVOICE_LINK_NOT_FOUND") {
        return "Link Invoice Tidak Ditemukan";
      } else if (respon.detail.type === "MASTER_INVOICE_NOT_FOUND") {
        return "Invoice Master Tidak Ditemukan";
      } else if (respon.detail.type === "INVOICE_NOT_FOUND") {
        return "Invoice Tidak Ditemukan";
      } else {
        return respon.detail.message;
      }
    case 500:
      if (respon.detail.type == "INTERNAL_SERVER_ERROR") {
        return "Kesalahan Server";
      } else {
        return respon.detail.message;
      }
    default:
      return respon.detail.message;
  }
};
