import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { saveState, loadState, removeState } from '../browser-storage/browser-storage'

// type InitialState = {
//   balance: number
// }
const type = 'balance'

const initialState = {
  balance: loadState(type),
}

export const balanceSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload
      saveState(type, action.payload)
    },
    removeBalance: (state) => {
      state.balance = 0
      removeState(type)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setBalance, removeBalance } = balanceSlice.actions

export default balanceSlice.reducer
