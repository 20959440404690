import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import Company from "../models/company";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "../../core/feature/store";
import { removeToken } from "../../core/feature/token/tokenSlice";
import { ValidateError, ValidateSuccess } from "./interceptor_error_message";
// import { Navigate, useNavigate } from "react-router-dom";
import { removeUser } from "../feature/user/userSlice";
import { useAppSelector } from "src/core/feature/hooks";

const reduxState = store.getState();


const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.log(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = async (response: AxiosResponse): Promise<AxiosResponse> => {
  // let message = "";
  // if (response && response.data) {
  //   const data = response.data as any
  //   // console.log(response.data.type)
  //   message = await ValidateSuccess(data)
  //   // console.log(response)
  //   // if (data) message = await ValidateSuccess(data)
  //   if (response.config.url.includes("authentication/admin/login") || response.config.url.includes("authentication/private/admin/token")) {

  //   } else {

  //     toast.success(message)
  //   }
  // }
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  let message = "";
  let data = error.response?.data as any;
  let detail = data.detail;
  if (error.response && error.response.data) {
    // const data = error.response?.data as any
    if (error.request.responseType == "blob") {
      const blob = new Blob([error.response.data as any]);
      const data = await blob.text();
      detail = JSON.parse(data).detail;
      message = ValidateError(JSON.parse(data), error.config);
    } else {
      message = ValidateError(data, error.config);
    }
    if (
      detail == "Not authenticated" ||
      detail.message == "Not authenticated"
    ) {
      store.dispatch(removeToken());
      store.dispatch(removeUser());
      message = "Silahkan Login Terlebih Dahulu";
      setTimeout(() => {
        window.location.href = `/`;
      }, 1500);
    }

    if ([422].includes(error.response?.status as number)) {
      message = "Terjadi Kesalahan, Data Tidak Dapat Diproses";
    }

    if ([405].includes(error.response?.status as number)) {
      message = "Metode Tidak Sesuai";
    }

    if ([401].includes(error.response?.status as number)) {
      store.dispatch(removeToken());
      store.dispatch(removeUser());
      // toast.error(message)
      setTimeout(() => {
        if (localStorage.getItem("company_sn_merchant_redux")) {
          const company: any = JSON.parse(
            localStorage.getItem("company_sn_merchant_redux") as string
          );
          window.location.href = `/${company.initial}/login`;
        }else{
          window.location.href = `/`;
        }
      }, 1500);
    }
    if ([502].includes(error.response?.status as number)) {
      message = "Terjadi Kesalahan Sistem, Silahkan Coba Beberapa Saat Lagi";
    }
    toast.error(message);
  }

  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
