import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import Company from '../../models/company'
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";

const type = "menu";

const initialState = {
  menu: loadState(type),
};

export const menuSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
      saveState(type, action.payload);
    },
    removeMenu: (state) => {
      state.menu = [];
      removeState(type);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMenu, removeMenu } = menuSlice.actions;

export default menuSlice.reducer;
