import { createSlice } from '@reduxjs/toolkit'
// import User from "../../models/user"
import { saveState, loadState, removeState } from '../browser-storage/browser-storage'

// type InitialState = {
//   user: User
// }
const type = 'user'

const initialState = {
  user: loadState(type),
}

export const userSlice = createSlice({
  name: type,
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      saveState(type, action.payload)
    },
    removeUser: (state) => {
      state.user = {}
      removeState(type)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, removeUser } = userSlice.actions

export default userSlice.reducer
