import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import Company from '../../models/company'
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";

const type = "company";

const initialState = {
  company: loadState(type),
};

export const companySlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
      saveState(type, action.payload);
    },
    removeCompany: (state) => {
      state.company = {};
      removeState(type);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompany, removeCompany } = companySlice.actions;

export default companySlice.reducer;
