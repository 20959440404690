import { createSlice } from "@reduxjs/toolkit";
// import User from "../../models/user"
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";

// type InitialState = {
//   user: User
// }
const type = "template";
const sidebarShowType = "sidebarShow";
const sidebarUnfoldableType = "sidebarUnfoldable";

const initialState = {
  sidebarShow: loadState(sidebarShowType),
  sidebarUnfoldable: loadState(sidebarUnfoldableType),
};

export const template = createSlice({
  name: type,
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload;
      saveState(sidebarShowType, action.payload);
    },
    setSidebarUnfoldable: (state, action) => {
      state.sidebarUnfoldable = action.payload;
      saveState(sidebarUnfoldableType, action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSidebarShow, setSidebarUnfoldable } = template.actions;

export default template.reducer;
