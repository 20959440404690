// import ErrorHandler from "./errorHandler";
import Config from "../../config/Config.ts";
import { setupInterceptorsTo } from "./interceptors.ts";
import axios from "axios";
import { check } from "src/config/global";
// import {
//     createArrayEntityFromObject,
//     createQueryUrl,
//     getEntity,
// } from "../../config/global";
setupInterceptorsTo(axios);

const baseUrl= Config.BaseUrlDev;

export const checkCompany = (initial) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/info/company/get/${initial}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const login = (data) => {
  return new Promise((resolve, reject) => {
    let postData = {
      companyId: data.companyId,
      username: data.username,
      password: data.password,
    };
    axios
      .post(`${baseUrl}authentication/merchant/login`, postData, {
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginVendor = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}commerce/sub-merchant/auth/login`, data, {
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const isFirstLogin = (data) => {
  return new Promise((resolve, reject) => {
    let postData = {
      password: data.password,
    };
    axios
      .post(
        `${baseUrl}user/account/merchant/change_password_first_login`,
        postData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const newPin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/account/merchant/security_code`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const cekToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}authentication/merchant/token`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cekTokenSub = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}commerce/sub-merchant/auth/token`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendOtp = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}authentication/guest/otp/request`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}authentication/merchant/logout`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logoutVendor = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}commerce/sub-merchant/auth/logout`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPasswordVendor = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}commerce/sub-merchant/auth/forgot-password`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const otpRequest = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}authentication/admin/otp/request`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeCompany = (companyId) => {
  let url = `${baseUrl}authentication/admin/change-company`;
  if (companyId) {
    url = `${baseUrl}authentication/admin/change-company?idCompany=${companyId}`;
  } else {
    url;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
