import { cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAvatar,
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { useState } from "react";

import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { removeCompany } from "src/core/feature/company/companySlice";
import { setLoading } from "src/core/feature/config/configSlice";
import { useAppSelector } from "src/core/feature/hooks";
import { removeMenu } from "src/core/feature/menu/menuSlice";
import { removeToken } from "src/core/feature/token/tokenSlice";
import { removeUser } from "src/core/feature/user/userSlice";
import { ValidateSuccess } from "src/core/services/interceptor_error_message";
import * as API from "../../core/services/api";
import avatar8 from "./../../assets/images/avatars/8.jpg";

const AppHeaderDropdown = () => {
  const company = useAppSelector((state) => state.company.company);
  const user = useAppSelector((state) => state.user.user);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logout = () => {
    dispatch(setLoading(true));
    let api = "";
    if (user?.userType == "MERCHANT") {
      api = API.logout();
    } else {
      api = API.logoutVendor();
    }
    api
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(removeToken());
        dispatch(removeUser());
        dispatch(removeMenu());
        toast.success(ValidateSuccess(res.data));
        navigate(`/${company?.initial}/login`, { replace: true });
      })
      .catch((e) => {
        dispatch(setLoading(false));
      });
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <CDropdown variant="nav-item">
      <Modal isOpen={modalIsOpen} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi</ModalHeader>
        <ModalBody>Apakah Anda yakin ingin keluar?</ModalBody>
        <ModalFooter>
          <CButton
            color=""
            type="button"
            size="sm"
            style={{
              background: "#fff",
              color: "#E50200",
              border: "1px solid #E50200",
            }}
            onClick={() => closeModal()}
          >
            Batal
          </CButton>
          <CButton
            color=""
            type="submit"
            size="sm"
            style={{
              background: "#0054E6",
              color: "#fff",
            }}
            onClick={() => logout()}
          >
            Keluar
          </CButton>
        </ModalFooter>
      </Modal>

      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avatar8} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader
          className="bg-light fw-semibold py-2 mb-2"
          style={{ fontSize: "16px" }}
        >
          {user?.name} - {user?.userType}
        </CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        {/* <CDropdownItem href="#">
          <FontAwesomeIcon icon={faLock} className="me-2" />
          Ganti Password
        </CDropdownItem> */}
        <CDropdownDivider />
        <CDropdownItem
          onClick={() => {
            openModal(true);
          }}
        >
          {/* <CIcon icon={cilAccountLogout} className="me-2" /> */}
          <FontAwesomeIcon icon={faPowerOff} className="me-2" />
          Keluar
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
