import React, { Component, Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import "./scss/style.scss";
import Routing from "./routes";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "./core/feature/hooks";
import LoadingOverlay from "react-loading-overlay";
import { AppContent, AppFooter, AppHeader, AppSidebar } from "./components";
import closepayLogo from "./assets/images/fav.png";

const loading = (
  <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div className="body flex-grow-1 px-3">
        <AppContent />
      </div>
      {/* <AppFooter /> */}
    </div>
  </div>
  // <div className="pt-3 text-center">
  //   <div className="sk-spinner sk-spinner-pulse"></div>
  // </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// // Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  // const unfoldable = useAppSelector((state) => state.sidebarUnfoldable);
  // const sidebarShow = useAppSelector((state) => state.sidebarShow);
  let isLoading = useAppSelector((state) => state.config.loading);
  const company = useAppSelector((state) => state.company.company);
  const [title, setTitle] = useState("Closepay");
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  useEffect(() => {
    // setTitle(company?.name);
    const favicon = getFaviconEl();

    // favicon.href = company?.logo != null ? company?.logo : closepayLogo;
    favicon.href = company?.companyLogo ? company?.companyLogo : closepayLogo;
    let companyName = company?.name;
    document.title = companyName != null ? companyName : "Micro Service";
  }, [company]);
  return (
    <BrowserRouter>
      <ToastContainer theme="colored"></ToastContainer>
      <LoadingOverlay active={isLoading} spinner text="Harap Menunggu ...">
        <Suspense fallback={loading}>
          <Routes>
            {Routing.map((prop, index) => {
              return (
                <Route
                  key={"menu-" + index}
                  path={prop.path}
                  element={<prop.element />}
                />
              );
              // if (prop.path == '') {
              //   return (
              //     <Route
              //       key={`menu-${index}`}
              //       path=""
              //       element={<Navigate to={prop.redirect} replace />}
              //     />
              //   )
              // } else {
              // }
            })}
          </Routes>
        </Suspense>
      </LoadingOverlay>
    </BrowserRouter>
  );
}

export default App;
