import React from "react";
const ChangeCompany = React.lazy(() =>
  import("./views/pages/changeCompany/ChangeCompany")
);
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Pin = React.lazy(() => import("./views/pages/pin/Pin"));
const cekCompany = React.lazy(() =>
  import("./views/pages/cekCompany/cekCompany")
);
const forgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/forgotPassword")
);
const Ship = React.lazy(() => import("./views/pages/marketplace/ship/Ship"));
const ProductMarketplace = React.lazy(() =>
  import("./views/pages/marketplace/product/productMarketplace")
);
const detailProductMarketplace = React.lazy(() =>
  import("./views/pages/marketplace/product/detailProductMarketplace")
);
const Category = React.lazy(() =>
  import("./views/pages/pos/category/Category")
);
const Product = React.lazy(() => import("./views/pages/pos/product/Product"));
const NewProduct = React.lazy(() =>
  import("./views/pages/pos/product/newProduct")
);
const editProduct = React.lazy(() =>
  import("./views/pages/pos/product/editProduct")
);
const detailProduct = React.lazy(() =>
  import("./views/pages/pos/product/detailProduct")
);
const Orders = React.lazy(() =>
  import("./views/pages/marketplace/orders/orders")
);
const DetailOrdersMarketplace = React.lazy(() =>
  import("./views/pages/marketplace/orders/edit")
);

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Profile = React.lazy(() =>
  import("./views/pages/marketplace/profile/profile")
);

const RoleVendor = React.lazy(() => import("./views/pages/vendor/Role"));
const NewRoleVendor = React.lazy(() => import("./views/pages/vendor/newRole"));
const EditRoleVendor = React.lazy(() =>
  import("./views/pages/vendor/editRole")
);
const Vendor = React.lazy(() => import("./views/pages/vendor/Vendor"));
const NewVendor = React.lazy(() => import("./views/pages/vendor/New"));
const EditVendor = React.lazy(() => import("./views/pages/vendor/Edit"));
const DetailVendor = React.lazy(() => import("./views/pages/vendor/Detail"));

const routes = [
  { path: "", name: "Companies", element: ChangeCompany },
  { path: "/:cid/login", name: "Login", element: Login },
  { path: "/:cid/login/new-pin", name: "Pin", element: Pin },
  { path: "/companies", name: "Companies", element: ChangeCompany },
  {
    path: "/:cid/login/forgot-password",
    name: "Lupa Password",
    element: forgotPassword,
  },
  { path: "/companies", name: "cekCompany", element: cekCompany },
  {
    path: "/:cid",
    name: "detailProductMarketplace",
    element: Profile,
  },

  { path: "/ship", name: "Ship", element: Ship },
  {
    path: "/marketplace/product",
    name: "ProductMarketplace",
    element: ProductMarketplace,
  },
  {
    path: "/marketplace/product/detail/:id",
    name: "detailProductMarketplace",
    element: detailProductMarketplace,
  },

  { path: "pos/category", name: "Category", element: Category },
  { path: "pos/product", name: "Product", element: Product },
  { path: "pos/product/new", name: "NewProduct", element: NewProduct },
  { path: "pos/product/edit/:id", name: "EditProduct", element: editProduct },
  {
    path: "pos/product/detail/:id",
    name: "DetailProduct",
    element: detailProduct,
  },
  {
    path: "/marketplace/orders/:id",
    name: "Detail Orders",
    element: DetailOrdersMarketplace,
  },
  { path: "/marketplace/orders", name: "Orders", element: Orders },
  {
    path: "/profile",
    name: "detailProductMarketplace",
    element: Profile,
  },
  {
    path: "/role-vendor",
    name: "roleVendor",
    element: RoleVendor,
  },
  {
    path: "/role-vendor/new",
    name: "NewRoleVendor",
    element: NewRoleVendor,
  },
  {
    path: "/role-vendor/edit/:id",
    name: "EditRoleVendor",
    element: EditRoleVendor,
  },
  {
    path: "/vendor",
    name: "Vendor",
    element: Vendor,
  },
  {
    path: "/vendor/new",
    name: "NewVendor",
    element: NewVendor,
  },
  {
    path: "/vendor/edit/:id",
    name: "EditVendor",
    element: EditVendor,
  },
  {
    path: "/vendor/detail/:id",
    name: "DetailVendor",
    element: DetailVendor,
  },
];

export default routes;
